@font-face {
  font-family: "Gelion Light";
  font-style: normal;
  font-weight: 300;
  src: local("Gelion Light"), url("../src/Assets/font/Gelion Light.woff") format("woff");
}

@font-face {
  font-family: "Gelion Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Gelion Regular"), url("../src/Assets/font/Gelion Regular.woff") format("woff");
}

@font-face {
  font-family: "Gelion Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Gelion Medium"), url("../src/Assets/font/Gelion Medium.woff") format("woff");
}

@font-face {
  font-family: "Gelion Semi Bold";
  font-style: normal;
  font-weight: 600;
  src: local("Gelion Semi Bold"), url("../src/Assets/font/Gelion SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Gelion Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Gelion Bold"), url("../src/Assets/font/Gelion Bold.woff") format("woff");
}

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

body {
  margin: 0px;
  padding: 0px;
  /* font-family: "Rubik", sans-serif; */
  font-family: "Gelion";
  font-weight: 400;
  font-family: "Gelion Regular";
  background-color: #020203;
  position: relative;
  width: 100%;
  min-height: 100%;
  padding-bottom: 250px;
}

.logo {
  width: 100%;
  max-width: 190px;
}

.navbar {
  padding: 14px 0px;
  /* position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 99; */
}

.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 16px;
  font-family: "Gelion Regular";
  font-weight: 400;
  position: relative;
  padding: 7px 18px;
}

.navbar .navbar-nav .nav-link::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 15px;
  background-color: #fff;
  right: 0px;
  top: 10px;
  transform: rotate(15deg);
}

.navbar .navbar-nav .nav-link:last-child::before {
  width: 0px;
  height: 0px;
}

.navbar .navbar-nav .nav-link.active {
  color: #0ab44d;
}

.navbar .navbar-nav .nav-link:is(:hover, :focus) {
  color: #09ab49;
}

.navbar .headbtn {
  background: linear-gradient(to right, #15db6e, #285cfb);
  padding: 13px 3px;
  font-size: 18px;
  border-radius: 50px;
  border: 0px;
  line-height: 22px;
  font-family: "Gelion Regular";
  font-weight: 400;
}

.navbar .headbtn:is(:hover, :focus) {
  box-shadow: 0px 10px 20px -10px rgba(13, 240, 101, 0.9);
}

.navbar .headbtn span {
  background-color: #020203;
  padding: 12px 31px;
  border-radius: 50px;
}

.navbar .headbtn span img {
  width: 100%;
  max-width: 12px;
  margin-left: 10px;
}

.navbar .navbar-nav .nav-link.headlastbtn {
  padding: 0px;
}

.banner {
  padding-top: 38vh;
  text-align: center;
  position: relative;
  height: 100vh;
  overflow: hidden; 
  background-image: url(../src/Assets/images/banner-video.gif);
  background-position-x: -720px;
  background-position-y: 33%;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .bannervideo video {
  width: 100%;
  height: auto;
} */

.banner {
  padding-top: 38vh;
  text-align: center;
  position: relative;
  height: 100vh;
  /* overflow: hidden; */
}

.banner::before {
  content: "";
  position: absolute;
  background-color: #020202d0;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.bannerbtn {
  background: linear-gradient(to right, #15db6e, #285cfb);
  padding: 11px 2px;
  font-size: 18px;
  font-family: "Gelion Regular";
  font-weight: 400;
  border-radius: 50px;
  border: 0px;
  outline: 0px;
  position: relative;
}

.bannerbtn:is(:hover, :focus) {
  box-shadow: 0px 10px 20px -10px rgba(13, 240, 101, 0.9);
}

.bannerbtn span {
  background-color: #020203;
  padding: 12px 31px;
  border-radius: 50px;
  color: #fff !important;
}
.bannerbtn a{
  text-decoration: none !important;
}
.banner .h1 {
  color: #fff;
  font-size: 110px;
  line-height: 120px;
  position: relative;
  font-family: "Gelion Semi Bold";
  font-weight: 600;
}

.banner .h6 {
  color: #949494;
  font-size: 24px;
  font-family: "Gelion Regular";
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
}

.banner .bannerbutton {
  margin-top: 50px;
}

.banner .btc {
  content: "";
  position: absolute;
  top: 160px;
  left: 25%;
  width: 100%;
  max-width: 45px;
}

.banner .polygon {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 100px;
  top: 170px;
  right: 25%;
}

.banner .tron {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 280px;
  right: -100px;
  top: 130px;
}

.banner .star {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 70px;
  top: 300px;
  left: 16%;
  z-index: -1;
}

.banner .spheres {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 35px;
  top: 45%;
  right: 22%;
  z-index: -1;
}

.banner .binance {
  content: "";
  position: absolute;
  bottom: 150px;
  left: 25%;
  width: 100%;
  max-width: 90px;
}

.banner .tether {
  content: "";
  position: absolute;
  bottom: -30px;
  left: -20px;
  width: 100%;
  max-width: 280px;
}

.banner .eth {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 180px;
  right: 32%;
  bottom: 0px;
}

.twoimages {
  position: relative;
  padding: 140px 0px;
}

.twoimages .spheresone {
  content: "";
  position: absolute;
  top: 100px;
  left: 35%;
  width: 100%;
  max-width: 40px;
}

.twoimages .pentagram {
  content: "";
  position: absolute;
  top: 80px;
  right: 0px;
  width: 100%;
  max-width: 150px;
}

section {
  padding: 50px 0px;
}

.headtitle {
  color: #fff;
  font-size: 34px;
  text-transform: capitalize;
  line-height: 40px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "Gelion Medium";
  font-weight: 500;
  line-height: 50px;
}

.subhead {
  color: #949494;
  font-size: 25px;
  font-family: "Gelion Regular";
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}

.problem .probox {
  background-color: #111314ab;
  min-height: 210px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 65px 30px 0px;
  backdrop-filter: blur(4px);
}

.problem .probox:hover {
  background-color: #2b2b2bbb;
}

.problem .probox .procontent {
  color: #fff;
  font-size: 16px;
  font-family: "Gelion Medium";
  font-weight: 500;
  margin-bottom: 0px;
  text-align: center;
}

.problem .proimg img {
  width: 100%;
  max-width: 35px;

}

.problem .proimg {
  width: 90px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #080809;
  margin: 0px auto -70px;
  z-index: 9;
  position: relative;
  border-radius: 0px 0px 7px 7px;
}

.problem .problembox {
  margin-top: 50px;
  position: relative;
}

.problem .leftboximg {
  width: 100%;
  max-width: 390px;
  content: "";
  position: absolute;
  left: -210px;
  top: 120px;
  z-index: -1;
}

.problem .starone {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 70px;
  top: 210px;
  left: 22%;
  z-index: -1;
}

.address {
  position: relative;
}

.address .binanceone {
  content: "";
  position: absolute;
  top: 50px;
  right: 25%;
  width: 100%;
  max-width: 90px;
  z-index: -1;
}

.address .bluebox {
  background: linear-gradient(to right, #10a5d7, #1c55fc);
  border-radius: 10px;
  padding: 20px 30px;
  margin-top: 35px;
}

.address .bluebox .bluesubox {
  padding: 0px 20px 0px 0px;
  text-align: center;
  min-height: 160px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.address .bluebox .bluesubox::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 60%;
  background-color: #fff;
  right: 0px;
}

.address .bluebox .row > div:last-child .bluesubox::after {
  width: auto;
  height: auto;
}

.address .bluebox .bluesubox .h5 {
  color: #fff;
  font-size: 28px;
  font-family: "Gelion Medium";
  font-weight: 500;
}

.address .bluebox .bluesubox .blueboxcontent {
  color: #fff;
  font-family: "Gelion Light";
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
}

.address .spherestwo {
  content: "";
  position: absolute;
  top: 160px;
  left: 8%;
  width: 100%;
  max-width: 35px;
  z-index: -1;
}

.millparent {
  background-color: #2b2c30eb;
  padding: 50px 22px;
  border-radius: 10px;
  position: relative;
  box-shadow: -20px 10px 10px 20px rgba(0, 0, 0, 0.9);
}

.millparent .tronone {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 180px;
  top: -50px;
  left: -90px;
}

.millparent .millstar {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 300px;
  bottom: -140px;
  z-index: -1;
}

.millparent .btcone {
  content: "";
  position: absolute;
  bottom: -100px;
  right: 5%;
  width: 100%;
  max-width: 60px;
}

.millparent .millcontent {
  color: #949494;
  font-size: 18px;
  margin-bottom: 0px;
  font-family: "Gelion Regular";
  font-weight: 400;
}

.millparent .millimg {
  width: 100%;
  max-width: 550px;
}

.millparent .millist {
  margin-bottom: 0px;
}

.millparent .millist li {
  list-style: none;
  min-height: 130px;
  position: relative;
}

.millparent .millist li::before {
  content: "";
  position: absolute;
  background-image: url(../src/Assets/images/diamond.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 28px;
  height: 28px;
  left: -55px;
  top: 0px;
  z-index: 9;
}

.millparent .millist {
  position: relative;
}

.millparent .millist::before {
  content: "";
  position: absolute;
  border-right: 1px dashed #0df065;
  height: 100%;
  top: 10px;
  left: -10px;
}

.millparent .row {
  margin-top: 40px;
}

.millparent .millrightimg {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 250px;
  right: -180px;
  bottom: 150px;
  z-index: -1;
}

.whyfindamill {
  position: relative;
}

.whyfindamill .picturetwo {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 310px;
  bottom: -170px;
  left: 0px;
  z-index: -1;
}

.compet .competbox .h5 {
  color: #0df065;
  font-size: 18px;
  font-family: "Gelion Medium";
  font-weight: 500;
  margin-bottom: 16px;
}

.compet .competlist {
  margin-bottom: 0px;
}

.competbox .competlist li {
  list-style: none;
  color: #949494;
  font-family: "Gelion Regular";
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 12px;
  position: relative;
}

.competbox .competlist li:last-child {
  padding-bottom: 0px;
}

.competbox .competlist li::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #0df065;
  border-radius: 50px;
  left: -22px;
  top: 6px;
}

.compet .competbox {
  border: 1px solid #111e1e;
  min-height: 200px;
  border-radius: 10px;
  background: linear-gradient(to right, #070b1e, #18181b);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 40px;
  width: 100%;
  max-width: 400px;
}

.compet {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 80px;
  position: relative;
}

.compet .rotatecoin {
  width: 100%;
  max-width: 145px;
  content: "";
  position: absolute;
  top: 30px;
}

.paracontent {
  color: #fff;
  font-size: 18px;
  font-family: "Gelion Regular";
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 30px;
}

.competition .paracontent {
  text-transform: capitalize;
}

.competition .competcontent {
  text-align: center;
  margin-top: 60px;
}

.advantages .adparentbox .h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  font-family: "Gelion Medium";
  text-align: center;
  line-height: 36px;
}

.advantages .adparentbox {
  margin-top: 50px;
}

.advantages .adparentbox .row .adcontent {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0px;
  font-family: "Gelion Regular";
  font-weight: 400;
  text-transform: capitalize;
}

.advantages .adparentbox .row {
  margin-top: 40px;
}

.advantages .adparentbox .adbox {
  background-color: #0d0f1154;
  min-height: 200px;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.advantages {
  position: relative;
}

.advantages .btctwo {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 160px;
  width: 100%;
  max-width: 100px;
  z-index: -1;
}

.advantages .polygonone {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 100px;
  right: 22%;
  bottom: -90px;
}

.advantages .rightimg {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 320px;
  right: -60px;
  bottom: -100px;
  z-index: -1;
}

.text-green {
  color: #0df065;
}

.howitwork .workbox .h3 {
  font-size: 26px;
  margin-bottom: 22px;
  font-weight: 500;
  font-family: "Gelion Medium";
}

.text-blue {
  color: #2d5dff;
}

.howitwork .worklist .text-blue {
  font-size: 18px;
  font-weight: 500;
  font-family: "Gelion Medium";
  padding-right: 14px;
  text-transform: capitalize;
}

.howitwork .worklist .workcontent {
  color: #949494;
  font-size: 18px;
  font-family: "Gelion Regular";
  font-weight: 400;
  text-transform: capitalize;
}

.howitwork .worklist li {
  list-style: none;
  position: relative;
  padding-bottom: 16px;
}

.howitwork .worklist li::before {
  content: "";
  position: absolute;
  background-image: url(../src/Assets/images/diamond.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  max-width: 21px;
  max-height: 21px;
  left: -35px;
  top: 2px;
}

.howitwork .worklist {
  margin-bottom: 0px;
}

.mt-50 {
  margin-top: 50px;
}

.howitwork .row {
  margin-top: 30px;
  align-items: center;
}

.howitwork .workimg {
  width: 100%;
  max-width: 560px;
}

.howitwork .bottomlist li {
  list-style: none;
  position: relative;
}

.howitwork .bottomlist li::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #2d5dff;
  border-radius: 50px;
  left: -18px;
  top: 6px;
}

.howitwork .bottomlist {
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.howitwork .bottomlist .bottomtext {
  font-size: 17px;
  font-weight: 500;
  font-family: "Gelion Medium";
  text-transform: capitalize;
}

.howitwork {
  position: relative;
}

.howitwork .workleftimg {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 260px;
  left: 0px;
  bottom: -180px;
  z-index: -1;
}

.howitwork .workrightimg {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 340px;
  right: -60px;
  bottom: -60px;
  z-index: -1;
}

.roadmap .roadmaptop .roadmapbtn {
  background: linear-gradient(to right, #0c8b44, #113f8a);
  width: 100%;
  max-width: 180px;
  height: 36px;
  border-radius: 4px;
  display: grid;
  place-content: center;
  margin-left: auto;
}

.roadmap .roadmaptop .roadmapbtn span {
  color: #fff;
  font-weight: 400;
  font-family: "Gelion Regular";
  font-size: 16px;
  padding: 4px 18px;
  background-color: #010103;
  min-width: 176px;
  text-align: center;
}

.roadmap .roadmaptop .roadmapbox img {
  width: 100%;
  max-width: 30px;
}

.roadmaptop .roadmapbox .roadinnerbox {
  min-height: 160px;
  border: 1px solid #3c3e44;
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  background: linear-gradient(to right, #1a1d26, #0a0b0c);
  padding: 10px 30px;
  display: flex;
    align-items: flex-start;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  /* display: grid;
  place-content: center; */
}

.roadmapbox .roadinnerbox .roadcontent {
  color: #949494;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Gelion Regular";
  margin-bottom: 0px;
  word-break: break-word;
}

.roadmap .roadmaptop .roadmapbox {
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding-bottom: 40px;
}

.roadmap .roadmaptop .roadmapbox > div {
  width: 33.33%;
}

.roadmap .roadmaptop .roadmapbox > div:nth-child(2) {
  text-align: center;
}

.roadmap .roadmaptop {
  width: 100%;
  max-width: 1000px;
  margin: 60px auto 0px;
  position: relative;
}

.roadmap .roadmaptop::before {
  content: "";
  position: absolute;
  border-left: 1px dashed #0ab44d;
  width: 1px;
  height: 95%;
  left: 50%;
  top: 10px;
  z-index: -1;
}

.roadmap .roadmaptop .roadmapbox:nth-child(even) .roadmapbtn {
  margin-left: 0px;
}

.roadmaptop .roadmapbox .roadline {
  position: relative;
}

.roadmaptop .roadmapbox .roadline::after {
  content: "";
  position: absolute;
  width: 60%;
  height: 1px;
  background-color: #0ab44d;
  right: -170px;
  top: 15px;
  z-index: -1;
}

.roadmaptop .roadmapbox:nth-child(even) .roadline::after {
  right: auto;
  left: -170px;
}

.roadmap {
  position: relative;
}

.roadmap .roadimgleft {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 350px;
  left: 0px;
  top: 32%;
  z-index: -1;
}

.roadmap .tether {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 170px;
  left: 0px;
  bottom: 100px;
  z-index: -1;
}

.roadmap .tron {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 190px;
  right: 0px;
  bottom: 0px;
}

.business .businessbox .h4 {
  color: #0df065;
  font-size: 24px;
  font-weight: 500;
  font-family: "Gelion Medium";
  margin-bottom: 12px;
  line-height: 26px;
}

.business .businessbox .bscontent {
  color: #949494;
  font-size: 18px;
  font-weight: 400;
  font-family: "Gelion Regular";
  margin-bottom: 0px;
}

.business .businessbox img {
  max-width: 80px;
}
.subs{
  color: white !important;
}
.business .businessbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #343435;
  height: 100%;
  border-radius: 5px;
  padding: 25px 30px;
  min-height: 170px;
  background: linear-gradient(to right, #0d0d0e, #232324);
}

.business .businessbox > div:first-child {
  padding-right: 50px;
}

.business .row {
  margin-top: 50px;
}

.business .row > div {
  margin-bottom: 24px;
}

.business {
  position: relative;
}

.business .bsleftimg {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 320px;
  left: 0px;
  bottom: -140px;
  z-index: -1;
}

.business .binance {
  width: 100%;
  max-width: 100px;
  content: "";
  position: absolute;
  z-index: -1;
  right: 170px;
  bottom: 18px;
}

.invest .investtop {
  background: linear-gradient(to left, #10a5d7, #1c55fc);
  border-radius: 14px;
  padding: 20px 30px;
  margin-top: 40px;
  position: relative;
}

.invest .investtop .coinsone {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 145px;
  top: -20px;
  left: -90px;
}

.invest .investtop .coinstwo {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 80px;
  right: -30px;
  bottom: 0px;
}

.invest .investtop .investbox {
  padding: 0px 10px 0px 10px;
  text-align: center;
  min-height: 180px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.invest .investtop .investbox::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 60%;
  background-color: #fff;
  right: -10px;
}

.investtop .row > div:last-child .investbox::after {
  width: auto;
  height: auto;
}

.invest .investtop .investbox .h2 {
  color: #fff;
  font-size: 48px;
  margin-bottom: 5px;
  font-weight: 500;
  font-family: "Gelion Medium";
}

.invest .investtop .investbox .h5 {
  color: #fff;
  font-size: 22px;
  font-family: "Gelion Light";
  font-weight: 300;
  margin-bottom: 0px;
}

.invest {
  position: relative;
}

.invest .investrightimg {
  width: 100%;
  max-width: 190px;
  content: "";
  position: absolute;
  right: 0px;
  bottom: 100px;
  z-index: -1;
}

.posibility .posbox {
  border: 1px solid #1d2923;
  background: linear-gradient(to right, #04050d, #17171a);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  padding: 10px 30px;
}

.posibility .posbox > div:first-child {
  margin-right: 20px;
}

.posibility .posbox img {
  width: 100%;
  max-width: 110px;
}

.posibility .posbox .h5 {
  color: #0df065;
  font-size: 20px;
  margin-bottom: 0px;
  text-transform: capitalize;
  line-height: 30px;
  font-family: "Gelion Medium";
  font-weight: 500;
}

.posibility .row {
  justify-content: center;
  margin-top: 40px;
}

.posibility {
  position: relative;
}

.posibility .posrightimg {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 350px;
  right: 0px;
  bottom: -140px;
  z-index: -1;
}

.team .teambox img {
  width: 100%;
  max-width: 350px;
  margin-top: 0px;
  object-fit: contain;
  object-position: top;
}

.team .teamdet {
  min-height: 120px;
  border: 1px solid #192a23;
  background-color: #222327c2;
  border-radius: 10px;
  display: grid;
  place-content: center;
  width: 100%;
  max-width: 270px;
  margin: -65px auto 0px;
  position: relative;
  text-align: center;
}

.team .teamdet .h5 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  font-family: "Gelion Medium";
  text-transform: capitalize;
  margin-bottom: 4px;
}

.team .teamdet span {
  color: #949494;
  font-size: 14px;
}

.team .teambox .teamimg {
  width: 100%;
  max-width: 310px;
  height: 300px;
  border: 1px solid #3f3f40;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  place-content: flex-start;
  margin: 0px auto;
}

.team .row {
  margin-top: 50px;
}

.team {
  position: relative;
}

.team .trontwo {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 190px;
  left: -65px;
  top: 180px;
  z-index: -1;
}

.team .workrightimgone {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 330px;
  right: 0px;
  bottom: -230px;
}

.team .teamtop {
  position: relative;
}

.team .teamtop .binancetwo {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 90px;
  left: -40px;
  bottom: -50px;
  z-index: -1;
}

.conclusion .lastbox {
  width: 100%;
  max-width: 1100px;
  margin: 0px auto;
  background-image: url(../src/Assets/images/lastimg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 60px;
}

.conclusion .headtitle {
  text-align: start;
}

.conclusion .paracontent {
  line-height: 36px;
  font-size: 17px;
  font-family: "Gelion Light";
  font-weight: 300;
  text-transform: capitalize;
}

.conclusion .earthimg {
  width: 100%;
  max-width: 450px;
}

.conclusion .lastbox .row {
  align-items: center;
}

.conclusion {
  position: relative;
  padding-bottom: 80px;
}

.conclusion .picturethree {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 330px;
  left: -40px;
  bottom: 0px;
  z-index: -1;
}

.conclusion .tetherone {
  content: "";
  position: absolute;
  bottom: 100px;
  right: 130px;
  width: 100%;
  max-width: 140px;
}

.footer {
  position: absolute;
  width: 100%;
  left: 0px;
  background: linear-gradient(to right, #06133af2, #06133ae7),
    url(../src/Assets/images/footerimg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px -35px 70px 18px rgba(6, 19, 58, 0.9);
}

.footer .footlogo {
  width: 100%;
  max-width: 200px;
}

.footer .footcontent {
  color: #949494;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 28px;
  padding-right: 100px;
  margin-top: 25px;
}

.footer .foothead {
  color: #fff;
  font-size: 26px;
  margin-bottom: 18px;
  font-family: "Gelion Medium";
  font-weight: 500;
}

.footer .footlist {
  padding-left: 0px;
  margin-bottom: 0px;
}

.footer .footlist li {
  list-style: none;
  padding-bottom: 24px;
}

.footer .footlist li:last-child {
  padding-bottom: 0px;
}

.footer .footlist li a {
  color: #949494;
  font-size: 17px;
  text-decoration: none;
  font-weight: 400;
  font-family: "Gelion Regular";
}

.footer .footlist li a:is(:hover, :focus) {
  color: #fff;
}

.footer .emailbox .h5 {
  color: #fff;
  font-size: 26px;
  margin-bottom: 20px;
  font-family: "Gelion Medium";
  font-weight: 500;
}

.footer .form-control {
  height: 65px;
  padding: 10px 20px;
  background-color: transparent;
  border: 0px;
  color: #949494;
  font-size: 18px;
  font-family: "Gelion Regular";
}

.footer .form-control:focus {
  box-shadow: none;
}

.footer .input-group-text {
  background: linear-gradient(to left, #10a5d7, #1c55fc);
  border: 0px;
  padding: 10px 60px;
  border-radius: 50px !important;
}

.footer .input-group-text:is(:hover, :focus) {
  /* box-shadow: 0px 10px 20px -10px rgba(13, 240, 101, 0.9); */
  transform: scale(1.05);
}

.footer .input-group-text a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-family: "Gelion Regular";
  text-decoration: none;
}

.footer .input-group {
  background-color: #353b4d;
  border-radius: 50px;
}

.footer .emailbox {
  width: 100%;
  max-width: 550px;
  margin-left: auto;
}

.footer .emailbox .socialmedia span {
  color: #949494;
  font-size: 18px;
  font-weight: 500;
  font-family: "Gelion Medium";
  padding-right: 16px;
}

.footer .emailbox .socialmedia {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.footer .emailbox .socialmedia a img {
  width: 100%;
  max-width: 26px;
}

.footer .emailbox .socialmedia a {
  margin-right: 14px;
  display: inline-block;
}

.footer .emailbox .socialmedia a img:is(:hover, :focus) {
  transform: scale(1.1);
}

.footer .emailbox .socialmedia a:last-child {
  margin-right: 0px;
}

.footer .hrline {
  background-color: #fff;
  opacity: 1;
  margin: 30px 0px 0px;
}

.footer .copyrights .copytext {
  color: #949494;
  font-size: 17px;
  margin-bottom: 0px;
  font-weight: 400;
  font-family: "Gelion Regular";
  text-align: center;
}

.footer .copyrights {
  padding: 30px 10px;
}

.footerbox {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
}

.footerbox .workleftimg {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 250px;
  left: 0px;
  bottom: -120px;
}

.footerbox .rightimg {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 280px;
  right: 0px;
  top: 180px;
}

.toppage {
  overflow: hidden;
}

.scrolltop {
  background-color: #010103;
}

.whyfindamill .millparent .row {
  align-items: center;
}

/* Media queries */


@media (max-width: 1400px) {
  .banner {
    background-position-x: -620px;
    background-position-y: 40%;
  }
}


@media (max-width: 1200px) {
  .container {
    width: 100%;
    max-width: 95%;
  }

  .banner .tron {
    max-width: 200px;
  }

  .banner .polygon {
    max-width: 50px;
  }

  .banner .eth {
    max-width: 100px;
  }

  .banner .tether {
    max-width: 170px;
  }

  .banner .binance {
    max-width: 50px;
  }

  .banner .btc {
    max-width: 30px;
  }

  .banner .star {
    max-width: 40px;
  }

  .banner .spheres {
    max-width: 25px;
  }

  .twoimages .spheresone {
    max-width: 30px;
  }

  .millparent .millimg {
    max-width: 480px;
  }

  .whyfindamill .picturetwo {
    max-width: 220px;
  }

  .millparent .millstar {
    max-width: 250px;
    left: 100px;
    bottom: -120px;
  }

  .advantages .btctwo {
    max-width: 80px;
    left: 20px;
  }

  .advantages .polygonone {
    max-width: 80px;
  }

  .advantages .rightimg {
    max-width: 260px;
  }

  .howitwork .workleftimg {
    max-width: 200px;
  }

  .howitwork .workrightimg {
    max-width: 280px;
  }

  .roadmap .roadimgleft {
    max-width: 280px;
  }

  .roadmap .tether {
    max-width: 120px;
    left: 30px;
  }

  .roadmap .tron {
    max-width: 140px;
  }

  .invest .investtop .coinsone {
    content: "";
    position: absolute;
    width: 100%;
    max-width: 120px;
    top: 8px;
    left: -40px;
  }

  .team .teamdet {
    max-width: 225px;
  }

  .conclusion .earthimg {
    max-width: 400px;
  }

  .conclusion .tetherone {
    max-width: 115px;
    right: 5px;
    bottom: 0px;
    z-index: -1;
  }

  .footerbox .workleftimg {
    max-width: 170px;
    bottom: -100px;
  }

  .footerbox .rightimg {
    max-width: 270px;
    top: 100px;
  }

  .banner {
    background-position-x: -520px;
    background-position-y: 46%;
  }
}

@media (max-width: 991px) {
  .navbar .headbtn {
    padding: 6px 2px;
    font-size: 13px;
    line-height: 23px;
  }

  .navbar .headbtn span {
    padding: 8px 24px;
  }

  .navbar .navbar-nav .nav-link {
    font-size: 13px;
  }

  .banner .h1 {
    font-size: 72px;
    line-height: 90px;
  }

  .banner .h6 {
    font-size: 22px;
  }

  .bannerbtn span {
    padding: 10px 31px;
  }

  .bannerbtn {
    padding: 10px 2px;
    font-size: 15px;
  }

  .banner .bannerbutton {
    margin-top: 30px;
  }

  .twoimages {
    padding: 100px 0px;
  }

  .headtitle {
    font-size: 24px;
    line-height: 34px;
  }

  .subhead {
    font-size: 18px;
  }

  .problem .probox .procontent {
    font-size: 14px;
  }

  .address .binanceone {
    max-width: 60px;
    right: 15%;
  }

  .address .bluebox .row > div:nth-child(2) .bluesubox::after {
    width: 0px;
    height: auto;
  }

  .address .bluebox .bluesubox {
    min-height: 120px;
  }

  .millparent .millcontent {
    font-size: 15px;
  }

  .millparent .millist li::before {
    max-width: 24px;
    height: 24px;
    left: -54px;
  }

  .logo {
    max-width: 140px;
  }

  .compet .rotatecoin {
    max-width: 130px;
  }

  .competbox .competlist li {
    font-size: 14px;
  }

  .paracontent {
    font-size: 16px;
  }

  .advantages .adparentbox .h2 {
    font-size: 26px;
  }

  .advantages .adparentbox .row > div {
    margin-bottom: 20px;
  }

  .advantages .adparentbox .adbox {
    min-height: 170px;
  }

  .advantages .adparentbox .row .adcontent {
    font-size: 16px;
  }

  .howitwork .workbox .h3 {
    font-size: 20px;
  }

  .howitwork .worklist .workcontent,
  .howitwork .worklist .text-blue {
    font-size: 14px;
  }

  .howitwork .worklist li::before {
    max-width: 18px;
    max-height: 18px;
    top: 5px;
  }

  .howitwork .bottomlist .bottomtext {
    font-size: 14px;
  }

  .howitwork .workleftimg {
    max-width: 150px;
    bottom: -130px;
  }

  .roadmapbox .roadinnerbox .roadcontent {
    font-size: 14px;
  }

  .roadmap .roadmaptop .roadmapbtn span {
    font-size: 15px;
    padding: 4px 20px;
  }

  .roadmaptop .roadmapbox .roadline::after {
    width: 65%;
    right: -150px;
  }

  .roadmaptop .roadmapbox:nth-child(even) .roadline::after {
    left: -155px;
  }

  .roadmap .roadimgleft {
    max-width: 250px;
  }

  .business .businessbox .h4 {
    font-size: 18px;
  }

  .business .businessbox .bscontent {
    font-size: 14px;
  }

  .business .businessbox img {
    max-width: 65px;
  }

  .invest .investtop .coinsone {
    max-width: 80px;
    left: -15px;
    top: 0px;
  }

  .invest .investtop .investbox {
    min-height: 120px;
  }

  .invest .investtop .row > div:nth-child(2) .investbox::after {
    width: 0px;
    height: auto;
  }

  .invest .investtop .investbox .h2 {
    font-size: 36px;
  }

  .invest .investtop .investbox .h5 {
    font-size: 17px;
  }

  .posibility .posbox img {
    max-width: 90px;
  }

  .posibility .posbox .h5 {
    font-size: 16px;
    line-height: 26px;
  }

  .team .teambox img {
    margin-top: 0px;
  }

  .team .teamdet {
    min-height: 100px;
  }

  .team .teamdet .h5 {
    font-size: 20px;
  }

  .team .row > div {
    margin-bottom: 24px;
  }

  .conclusion .earthimg {
    max-width: 350px;
  }

  .conclusion .lastbox {
    padding: 45px 60px 40px;
  }

  .footer .footerbox .row > div {
    margin-bottom: 30px;
  }

  .footer .footerbox .row > div:last-child {
    margin-bottom: 0px;
  }

  .footer .emailbox {
    margin-left: 0px;
  }

  .footer .foothead {
    font-size: 20px;
  }

  .footer .footlist li a {
    font-size: 14px;
  }

  .footer .footlist li {
    padding-bottom: 18px;
  }

  .footer .emailbox .h5 {
    font-size: 18px;
  }

  .footer .form-control {
    height: 55px;
    font-size: 14px;
  }

  .footer .input-group-text a {
    font-size: 14px;
  }

  .footerbox .rightimg {
    max-width: 200px;
    top: auto;
    bottom: -40px;
  }

  .footer .copyrights .copytext {
    font-size: 14px;
  }

  .footer .footcontent {
    font-size: 14px;
    line-height: 25px;
    margin-top: 20px;
  }

  .problem .problembox .row > div {
    margin-bottom: 20px;
  }

  .container {
    max-width: 100%;
  }

  .banner .tether {
    max-width: 130px;
  }

  .banner .eth {
    max-width: 70px;
    bottom: 0px;
  }
  .banner .tether
{
  bottom: -20px !important;
}
  .whyfindamill .picturetwo {
    max-width: 150px;
    bottom: -130px;
  }

  .millparent .btcone {
    max-width: 40px;
  }

  .advantages .btctwo {
    max-width: 50px;
    bottom: 40px;
  }

  .advantages .polygonone {
    max-width: 60px;
    bottom: -50px;
  }

  .posibility .posrightimg {
    max-width: 260px;
    bottom: -100px;
  }

  .team .teamtop .binancetwo {
    left: -10px;
    max-width: 70px;
  }

  .conclusion .tetherone {
    max-width: 90px;
    bottom: 30px;
  }

  .conclusion .lastbox .row > div:first-child {
    margin-bottom: 30px;
  }

  .invest .investtop .coinstwo {
    right: -10px;
  }

  .address .bluebox .bluesubox .h5 {
    font-size: 22px;
  }

  .roadmaptop .roadmapbox .roadinnerbox {
    min-height: 130px;
  }

  .conclusion .paracontent {
    font-size: 16px;
    line-height: 30px;
  }

  .banner {
    background-position-x: -400px;
  }
}

@media (max-width: 850px) {
  .roadmaptop .roadmapbox .roadline::after {
    right: -120px;
  }

  .roadmaptop .roadmapbox:nth-child(even) .roadline::after {
    left: -120px;
  }
}

@media (max-width: 767px) {
  .navbar {
    background-color: #010103;
  }

  .navbar .navbar-collapse {
    border-top: 1px solid #070b1e;
    margin-top: 10px;
    padding: 7px 0px 12px;
  }

  .navbar .navbar-nav .nav-link::before {
    width: 0px;
    height: auto;
  }

  .navbar .navbar-nav .nav-link {
    padding: 7px 12px;
  }

  .navbar .navbar-nav .nav-link.headlastbtn {
    margin-top: 10px;
    margin-left: 10px;
  }

  .compet {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
    margin-top: 30px;
  }

  .compet .rotatecoin {
    max-width: 100px;
    top: 180px;
  }

  .whyfindamill .millparent .row {
    flex-direction: column-reverse;
  }

  .whyfindamill .millparent .row > div:last-child {
    margin-bottom: 20px;
  }

  .millparent .millist li::before {
    left: -34px;
    max-width: 22px;
    height: 22px;
  }

  .millparent .millist::before {
    left: 8px;
  }

  .millparent {
    padding: 30px 25px;
  }

  .navbar .headbtn span img {
    width: 100%;
    max-width: 8px;
    position: relative;
    top: -2px;
  }
}

@media (max-width: 576px) {
  .banner .binance {
    left: 5%;
    bottom: 120px;
  }

  .banner .btc {
    top: 120px;
    left: 5%;
  }

  .banner .polygon {
    top: 150px;
  }

  .banner .tron {
    max-width: 160px;
  }

  .banner .h1 {
    font-size: 64px;
    line-height: 80px;
  }

  .banner .h6 {
    font-size: 20px;
  }

  .bannerbtn {
    font-size: 14px;
  }

  .bannerbtn span {
    padding: 11px 22px;
  }

  .banner .star {
    left: 2%;
    top: 250px;
    max-width: 30px;
  }

  .headtitle {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 7px;
  }

  .subhead {
    font-size: 16px;
    line-height: 28px;
  }

  .problem .problembox {
    margin-top: 30px;
  }

  .problem .problembox .row > div:last-child {
    margin-bottom: 0px;
  }

  section {
    padding: 30px 0px;
  }

  .address .binanceone {
    right: 0%;
    top: 90px;
  }

  .address .bluebox .bluesubox::after {
    width: 0px;
    height: auto;
  }

  .address .bluebox .bluesubox {
    padding: 20px 0px;
    min-height: auto;
  }

  .address .bluebox .bluesubox .h5 {
    font-size: 18px;
  }

  .address .bluebox .bluesubox .blueboxcontent {
    font-size: 12px;
  }

  .millparent .millcontent {
    font-size: 14px;
  }

  .millparent .millist li {
    min-height: auto;
    padding-bottom: 40px;
  }

  .whyfindamill .picturetwo {
    bottom: -50px;
  }

  .millparent .millstar {
    max-width: 200px;
    bottom: -80px;
  }

  .compet .competbox .h5 {
    font-size: 16px;
  }

  .competbox .competlist li {
    font-size: 13px;
  }

  .advantages .adparentbox .h2 {
    font-size: 20px;
  }

  .advantages .adparentbox .row .adcontent {
    font-size: 15px;
  }

  .advantages .rightimg {
    max-width: 220px;
    bottom: -60px;
  }

  .advantages .polygonone {
    max-width: 50px;
    bottom: -30px;
  }

  .howitwork .workbox .h3 {
    font-size: 18px;
    margin-bottom: 18px;
  }

  .howitwork .worklist li::before {
    left: -28px;
  }

  .howitwork .workimg {
    max-width: 400px;
  }

  .howitwork .bottomlist {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .howitwork .bottomlist li {
    padding-bottom: 8px;
  }

  .howitwork .bottomlist li:last-child {
    padding-bottom: 0px;
  }

  .howitwork .workleftimg {
    left: -40px;
    max-width: 130px;
    bottom: -120px;
  }

  .howitwork .workrightimg {
    max-width: 230px;
  }

  .roadmap .roadmaptop .roadmapbox {
    flex-direction: column;
    gap: 50px;
    justify-content: start;
    align-items: center;
  }

  .roadmap .roadmaptop .roadmapbox:nth-child(even) {
    flex-direction: column-reverse;
  }

  .roadmap .roadmaptop .roadmapbtn {
    margin: 0px auto;
  }

  .roadmaptop .roadmapbox .roadline::after {
    width: 0%;
    height: auto;
  }

  .roadmap .roadmaptop .roadmapbox > div {
    width: 100%;
  }

  .roadmaptop .roadmapbox .roadinnerbox,
  .roadmap .roadmaptop .roadmapbox:nth-child(even) .roadmapbtn {
    margin: 0px auto;
  }

  .roadmap .roadmaptop::before {
    height: 98%;
    top: 0px;
  }

  .roadmap .tether,
  .roadmap .tron {
    max-width: 90px;
  }

  .business .row {
    margin-top: 30px;
  }

  .business .bsleftimg {
    max-width: 230px;
    bottom: -40px;
  }

  .invest .investtop .investbox::after {
    width: 0px;
    height: auto;
  }

  .invest .investtop .investbox {
    min-height: auto;
    padding-bottom: 40px;
  }

  .invest .investtop .row > div:last-child .investbox {
    padding-bottom: 0px;
  }

  .posibility .row {
    margin-top: 25px;
  }

  .posibility .row > div:first-child {
    margin-bottom: 20px;
  }

  .team .trontwo {
    max-width: 150px;
  }

  .team .teamdet .h5 {
    font-size: 18px;
  }

  .business .row > div:last-child {
    margin-bottom: 0px;
  }

  .business .binance {
    bottom: -10px;
    max-width: 80px;
    right: 30px;
  }

  .team .workrightimgone {
    max-width: 220px;
    bottom: -160px;
  }

  .conclusion .lastbox {
    padding: 45px 40px 40px;
  }

  .conclusion .paracontent {
    font-size: 14px;
    line-height: 28px;
  }

  .footer .footcontent {
    padding-right: 0px;
  }

  .footer .footlogo {
    max-width: 150px;
  }

  .footer .foothead {
    font-size: 18px;
    margin-bottom: 14px;
  }

  .footer .footlist li {
    padding-bottom: 12px;
  }

  .footer .form-control {
    height: 45px;
  }

  .footer .input-group-text {
    padding: 10px 25px;
  }

  .footer .emailbox .h5 {
    font-size: 16px;
    margin-bottom: 14px;
  }

  .footer .emailbox .socialmedia span {
    font-size: 14px;
  }

  .footer .copyrights {
    padding: 20px 10px;
  }

  .footerbox .workleftimg {
    max-width: 140px;
    left: -35px;
  }

  .footerbox .rightimg {
    max-width: 150px;
    right: -70px;
  }

  .team .teamtop .binancetwo {
    max-width: 60px;
  }

  .twoimages .pentagram {
    max-width: 120px;
  }

  .invest .investtop .investbox .h2 {
    font-size: 30px;
  }

  .footer .emailbox .socialmedia a img {
    max-width: 20px;
  }

  .problem .probox {
    min-height: 180px;
  }

  .banner {
    background-position-x: 0px;
    background-position-y: 22px;
  }
}

@media (max-width: 450px) {
  .banner {
    background-position-x: -85px;
  }
}

@media (max-width: 400px) {
  .business .businessbox {
    flex-direction: column-reverse;
  }

  .business .businessbox > div:last-child {
    margin-bottom: 16px;
  }

  .business .businessbox > div:first-child {
    padding-right: 0px;
    text-align: center;
  }

  .invest .investtop .investbox .h5 {
    font-size: 16px;
  }

  .invest .investtop {
    padding: 30px 30px;
  }

  .logo {
    max-width: 120px;
  }

  .banner {
    background-position-x: -100px;
  }
}

@media (max-width: 350px) {
  .banner {
    background-position-x: -130px;
  }
}

@media (min-width: 992px)  and (max-width: 1200px)  {
.footerbox .rightimg {
  max-width: 180px;

}
}


@media only screen and (min-width:992px) and (max-width:1199px)
{
  .banner .tether
  {
    bottom: -20px !important;
  }
}

.roadinnerbox li
{
  display: flex;
  position: relative;
  padding-left: 15px;
  align-items: flex-start;
  margin-bottom: 10px;
}
.roadinnerbox li::before
{
  content: "";
  width:8px;
  height: 8px;
  Background: linear-gradient(to right, #0c8b44 50%, #113f8a 51%);
  border-radius: 50%;
  position: absolute;
  left:0px;
  top:7px;
}
/* .roadcontent
{
  display: flex;
  align-items: center;
  flex-direction: column;
} */